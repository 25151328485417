import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import Image from 'react-bootstrap/Image';

import ScheduleDemo from './components/schedule-demo/ScheduleDemo';
import RegisterFreeAccount from './components/marketing/RegisterFreeAccount';
import LandingPage from './components/landing-page/landing-page';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { initPendo } from './common/Pendo';

const App = () => {
  
  React.useEffect(() => {
    initPendo();
  }, []);

  return (
    <>
    <div className="content-area">
      <div className="header">
        <div className="header-content">
          <Image
            className="header-logo"
            src="./images/pendo-chevron-pink.svg"
          />
          <h1 className="header-text"> Pendo Experience </h1>
        </div>
      </div>
      <div className='wrapper'>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage/>} />
            <Route path="/free-account" element={<RegisterFreeAccount/>} />
            <Route path="/schedule-demo" element={<ScheduleDemo/>} />
          </Routes>
        </BrowserRouter>
      </div>
      
    </div>
    {/* <div className="footer"></div> */}
    </>
  );
};

export default App;

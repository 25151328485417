import './ResourceTile.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const ResourceTile = (props) => (
  <>
    <Col xs={12} sm={6} md={3} style={{ marginBottom: '30px' }}>
      <a href={props.url} target="_blank" rel="noreferrer">
        <Card className={`resource-tile ${props.url ? 'with-link' : ''}`}>
          <Card.Body
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: '40px',
              aspectRatio: 1 / 1,
            }}
          >
            <Card.Img
              className="resource-tile-image"
              src={`${props.imgSrc}`}
              style={props.imgStyle}
            />
          </Card.Body>
          <Card.Title
            style={{
              position: 'absolute',
              bottom: '0px',
              left: '50%',
              transform: 'translate(-50%, 0)',
              width: '100%',
            }}
            className="resource-tile-title"
          >
            {props.title}
          </Card.Title>
        </Card>
      </a>
      {props.docUrl && (
        <a
          href={props.docUrl}
          target="_blank"
          rel="noreferrer"
          style={{ marginLeft: '5px' }}
          className="doc-link"
        >
          Login and Usage Instructions
        </a>
      )}
    </Col>
  </>
);

ResourceTile.propTypes = {
  title: PropTypes.string,
  imgSrc: PropTypes.string,
  imgStyle: PropTypes.object,
  url: PropTypes.string,
  docUrl: PropTypes.string,
};

export default ResourceTile;

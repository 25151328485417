import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import './ScheduleDemo.scss';


function RequestDemo() {
  return (
    <Form className="form-body"> 
        <h2>Schedule a customized demo to see AcmeCRM in action!</h2>
        <p></p> 
      <Form.Group as={Col} className="form-field" controlId="formEmail">
        <Form.Label>Email Address</Form.Label>
        <Form.Control type="email" />
      </Form.Group>

      <Form.Group as={Col} className="form-field" controlId="formFirstName">
        <Form.Label>First Name</Form.Label>
        <Form.Control type="first name"  />
      </Form.Group>

      <Form.Group as={Col} className="form-field" controlId="formLastName">
        <Form.Label>Last Name</Form.Label>
        <Form.Control type="last name" />
      </Form.Group>

      <Form.Group as={Col} className="form-field" controlId="formCompanyName">
        <Form.Label>Company Name</Form.Label>
        <Form.Control type="company name" />
      </Form.Group>

      <Form.Group as={Col} className="form-field" controlId="formCompanySize">
        <Form.Label>Company Size</Form.Label>
        <Form.Control type="company size" />
      </Form.Group>

      <Form.Group as={Col} className="form-field" controlId="formPhoneNumber">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control type="phone number" />
      </Form.Group>
      
      <div className="d-grid gap-2">
      <Button variant="primary" size="lg" type="submit" href="https://crm.pendoexperience.io">
        Get a demo!
      </Button>
      </div>
    </Form>
  );
}

export default RequestDemo;